@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'clash_displaybold';
  src: url('./assets/fonts/clashdisplay-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/clashdisplay-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'clash_displayregular';
  src: url('./assets/fonts/clashdisplay-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/clashdisplay-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'clash_displaysemibold';
  src: url('./assets/fonts/clashdisplay-semibold-webfont.woff2') format('woff2'),
    url('./assets/fonts/clashdisplay-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'nexaregular';
  src: url('./assets/fonts/nexaregular-webfont.woff2') format('woff2'),
    url('./assets/fonts/nexaregular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'nexaxbold';
  src: url('./assets/fonts/nexaxbold-webfont.woff2') format('woff2'),
    url('./assets/fonts/nexaxbold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'nexa_boldregular';
  src: url('./assets/fonts/nexa_bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/nexa_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'neue_montrealregular';
  src: url('./assets/fonts/neuemontreal-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/neuemontreal-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'neue_montrealbold';
  src: url('./assets/fonts/neuemontreal-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/neuemontreal-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

#accordion-collapse button[aria-expanded="true"] {
  background-color: transparent;
}

#accordion-collapse button[aria-expanded="true"] .plus {
  display: none;
}

#accordion-collapse button[aria-expanded="true"] .cross {
  display: block;
}

#accordion-collapse button[aria-expanded="false"] .plus {
  display: block;
}

#accordion-collapse button[aria-expanded="false"] .cross {
  display: none;
}

.social-icon:hover img {
  filter: brightness(10);
}



.scroll-bar::-webkit-scrollbar {
  height: 10px;
  border-radius: 6px;
}

/* Track */
.scroll-bar::-webkit-scrollbar-track {
  background: #f1f1f1;
  height: 4px;
}

/* Handle */
.scroll-bar::-webkit-scrollbar-thumb {
  background: #2F3F87;
}

html {
  scroll-behavior: smooth;
}

@media (max-width: 767px) {
  .bg-btn .text-break {
    word-break: break-all;
  }
}



.show {
  display: block !important;
}

.shape {
  position: absolute;
  top: 300px;
  transform: translate(-50%, -50%);
  max-width: 300px;

}

.anime {
  animation: animName 15s linear infinite;
  transform-origin: center;

}

#container {
  width: 200px;
  height: 200px;
  margin: 10px auto;
  position: relative;
  animation: spin 10s linear infinite;
}

.item {
  width: 58px;
  height: 30px;
  position: absolute;
}

.item-2 {
  animation: spin 10s linear infinite reverse;
}

@keyframes spin {
  100% {
    transform: rotate(-1turn);
  }
}


@media(max-width:1200px) {
  .anime {
    max-width: 440px;

  }

  .shape {
    top: 236px;
    max-width: 213px;
  }
}


@media(max-width:1024px) {
  .anime {
    max-width: 370px;
  }

  .shape {
    top: 190px;
    max-width: 180px;
  }
}


@media(max-width:768px) {
  .anime {
    max-width: 300px;
  }

  .shape {
    top: 150px;
    max-width: 160px;
  }
}


@media(max-width:576px) {
  .anime {
    max-width: 300px;
  }

  .shape {
    top: 150px;
    max-width: 150px;
  }
}



@-moz-keyframes animName {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes animName {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes animName {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bg-btn,
.btnGrad {
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.bg-btn:hover,
.btnGrad:hover {
  background-size: 200% 100%;
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.bg-btn:hover img {
  transform: translateY(-5px);
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.groupImg {
  position: relative;
  overflow: hidden;
}

.groupImg:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #18245e;
  transition: .5s;
  z-index: 2;

}

.groupImg:hover::before {
  top: 0;
}

.socialIcon img,
.groupImg h5,
.groupImg h6,
.groupImg h4,
.groupImg img {
  transition: 0.5s;
  position: relative;
  z-index: 5;
}

.socialIcon {
  transition: 0.5s;

}

.socialIcon:hover img,
.groupImg:hover img {
  transform: rotateY(360deg);
}